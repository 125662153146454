import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import { ReactComponent as ChevronUpIcon } from "assets/images/icons/chevron_up.svg";

const PXInfo = (props) => {
  const [open, setOpen] = useState(true);

  return (
    <Alert
      variant="warning"
      className={`mb-2 ${props.mobile ? "block md:hidden" : "hidden md:block"}`}
    >
      <Alert.Heading
        as="h4"
        className={`font-semibold cursor-pointer select-none ${
          open ? "mb-3" : ""
        }`}
        onClick={(e) => setOpen(!open)}
      >
        <div className="flex justify-between items-center">
          <div className="info-exchange">
            Information about your part exchange
          </div>
          <ChevronUpIcon
            className={`stroke-warning-yellow ${open ? "" : "rotate-180"}`}
          />
        </div>
      </Alert.Heading>

      {open && (
        <>
          <p className="mb-3 info-text">
            Before we can allow you to submit your finance application, the
            dealer needs to contact you about your part exchange and assess the
            condition, value and any finance that you may have outstanding on
            the car. This will then give you an accurate equity figure that you
            can use as a deposit towards your new car. You can contact the
            dealer direct and they will have your details or you can wait for
            them to call you, they usually call within the hour.
          </p>
          <p className="info-text">
            If you wish to continue with your finance application immediately,
            you can do so by removing your part exchange from your quote.
          </p>
        </>
      )}
    </Alert>
  );
};

PXInfo.defaultProps = {
  mobile: false,
};

export default PXInfo;
