import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Badge from "react-bootstrap/Badge";

import { getNotificationCount } from "services";
import { logout } from "store/actions/auth";

import { ReactComponent as HelpIcon } from "assets/images/dashboard_icons/help.svg";
import { ReactComponent as HomeIcon } from "assets/images/dashboard_icons/home.svg";
import { ReactComponent as LogoutIcon } from "assets/images/dashboard_icons/logout.svg";
import { ReactComponent as MyOffersIcon } from "assets/images/dashboard_icons/myoffers.svg";
import { ReactComponent as MyProfileIcon } from "assets/images/dashboard_icons/myprofile.svg";
import { ReactComponent as NotificationsIcon } from "assets/images/dashboard_icons/notifications.svg";
import { ReactComponent as RetailersIcon } from "assets/images/dashboard_icons/retailers.svg";
import { ReactComponent as SearchIcon } from "assets/images/dashboard_icons/search.svg";
import { ReactComponent as SubmittedAppsIcon } from "assets/images/dashboard_icons/submittedapps.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";

const menuItems = [
  { icon: HomeIcon, label: "Home", url: "/" },
  { icon: MyOffersIcon, label: "My offers", url: "/offers" },
  {
    icon: SubmittedAppsIcon,
    label: "Submitted applications",
    url: "/applications/submitted",
  },
  { icon: RetailersIcon, label: "Retailers", url: "/retailers" },
  { icon: MyProfileIcon, label: "My profile", url: "/myprofile" },
  { icon: NotificationsIcon, label: "Notifications", url: "/notifications" },
  { icon: HelpIcon, label: "Help center", url: "/helpcenter" },
];

const SidebarMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ notifications, setNotifications ] = useState(0);

  const user = useSelector(state => state?.auth?.user);

  useEffect(() => {
    getNotificationCount()
      .then(res => setNotifications(res.count));
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="sidebar-menu">
      <div className="logo">
        <Logo />
      </div>
      <div className="search-bar">
        <SearchIcon className="search-icon" />
        <input type="text" placeholder="Search" />
      </div>
      <div style={{ overflowY: "auto" }} className="flex h-100 flex-col">
        <div className="menu-items">
          {menuItems.map((menuItem, index) => (
            <Link key={index} to={menuItem.url} className="menu-item">
              <menuItem.icon className="menu-item-icon" />
              {menuItem.label}

              { menuItem.label === "Notifications" && notifications > 0 && (
                <Badge bg="danger" className="ms-auto">{ notifications }</Badge>
              )}
            </Link>
          ))}
        </div>
        <div className="mt-auto">
          <button className="profile-button" onClick={() => navigate('/myprofile')}>
            <MyProfileIcon className="profile-icon" />
            { user.first_name } { user.last_name }
          </button>
          <button className="logout-button" onClick={handleLogout}>
            <LogoutIcon className="logout-icon" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
