import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";

import { checkVerificationCode, getMetaData, loginUser } from "services";
import { loginSuccessful, setUser } from "store/actions/auth";
import { setMetaData } from "store/actions/general";

const ConfirmationCodePage = (props) => {
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pinCode, setPinCode] = useState(null);
  const [showCodeError, setShowCodeError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResend = (e) => {
    e.preventDefault();

    loginUser(props.data)
      .then((res) => {
        setCodeSent(true);
      })
      .catch((err) => {});
  };

  const onProceed = () => {
    setLoading(true);
    checkVerificationCode({ ...props.data, code: pinCode, type: "login" })
      .then((res) => {
        const token = res.token;

        dispatch(setUser(res.user));

        getMetaData().then((res) => {
          dispatch(setMetaData(res));
          dispatch(loginSuccessful(token));
        });
      })
      .catch((err) => {
        setShowCodeError(true);
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="guest-page-register">
      <div className="guest-content-register">
        <div className="guest-title">
          {props.data?.name}, we’ve got your details!
        </div>

        <div className="form-container">
          <div className="form-item form-spacing">
            <label>
              Please enter the 6 digit code we have sent to your{" "}
              {props.data?.email ? "email inbox" : "phone"}.
            </label>

            <PinInput
              length={6}
              focus
              secret
              secretDelay={250}
              type="numeric"
              inputMode="numeric"
              autoSelect
              onComplete={(value) => setPinCode(value)}
            />

            {showCodeError && (
              <div className="form-error text-center">
                Invalid code, please try again or click below to send another
                code
              </div>
            )}
          </div>

          <div className="form-item">
            <button className="light-blue full" onClick={onProceed} disabled={loading}>
              View offers
            </button>
          </div>

          <div className="form-info mt-3">
            {codeSent ? (
              <a className="text-green-600 success">Code successfully sent</a>
            ) : (
              <>
                <span>Didn’t receive the code?</span>
                <a href="#" onClick={handleResend}>
                  Send again
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCodePage;
