import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { formatCurrency } from "utils/currency";
import { hasPxRequest } from "services";
import LenderLogo from "components/LenderLogo";

const FullQuoteInfo = (props) => {
  const navigate = useNavigate();

  const [isPxRequest, setIsPxRequest] = useState(false);

  useEffect(() => {
    if (props.application) {
      hasPxRequest(props.application?.applicationGuid).then((res) => {
        setIsPxRequest(res);
      });
    }
  }, [props.application]);

  const canShowSubmitToLender = () => {
    if (isPxRequest.pending) {
      return false;
    }

    const anyQuotesAccepted = props.application.lenderList.some((lender) => {
      return lender.quoteList.some((quote) => {
        return ["approved", "pending", "referred"].includes(quote.status);
      });
    });

    if (anyQuotesAccepted) {
      return false;
    }

    if (props.quote.status === "Declined") {
      return false;
    }

    return true;
  };

  return (
    <div
      className={`full-quote-info-container ${
        props.embedded ? "embedded" : ""
      }`}
    >
      <Row className="info-row">
        <Col xs={12} md={6}>
          <LenderLogo image={props.lender.lenderLogo} />
        </Col>
        <Col xs={12} md={6} className="align-content-center text-right">
          <span className="price-per-month">
            {formatCurrency(props.quote.payments)}
          </span>
          <label className="price-per-month-label">per month</label>
        </Col>
      </Row>

      <Row className="quote-row">
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12}>
              <div className="quote-info-label">Lender</div>
              <div className="quote-info-value">{props.lender.lenderName}</div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Finance type</div>
              <div className="quote-info-value">{props.quote.productTypeDesc}</div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Purchase price</div>
              <div className="quote-info-value">
                {formatCurrency(props.application.vehicle.salesValue)}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Cash deposit</div>
              <div className="quote-info-value">
                {formatCurrency(props.application.deposit)}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Part ex deposit</div>
              <div className="quote-info-value">
                {formatCurrency(props.application.equity)}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">First payment</div>
              <div className="quote-info-value">
                {formatCurrency(props.quote.payments)}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">
                {props.application.term} monthly payments
              </div>
              <div className="quote-info-value">
                {formatCurrency(props.quote.payments)}
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6}>
          <Row>
            <Col xs={12}>
              <div className="quote-info-label">Final payment</div>
              <div className="quote-info-value">
                {formatCurrency(props.quote.residualValue)}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Interest rate (APR)</div>
              <div className="quote-info-value">{props.quote.apr}</div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Amount of credit</div>
              <div className="quote-info-value">
                {formatCurrency(
                  props.application.vehicle.salesValue -
                    props.application.deposit -
                    props.application.equity
                )}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Total charge for credit</div>
              <div className="quote-info-value">
                {formatCurrency(props.quote.totalInterest)}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Total amount payable</div>
              <div className="quote-info-value">
                {formatCurrency(props.quote.totalPayable)}
              </div>
            </Col>
            <Col xs={12}>
              <div className="quote-info-label">Option to purchase fee</div>
              <div className="quote-info-value">
                {formatCurrency(props.quote.optionToPurchaseFee)}
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} className="quote-actions">
          <button onClick={props.onClose} className="secondary">
            Close full quote
          </button>

          {props.showCheckout && canShowSubmitToLender() && (
            <button
              onClick={(e) =>
                navigate(
                  `/offers/${props.application.applicationGuid}/checkout/${props.quote.quoteGuid}`
                )
              }
              className="checkout-button"
            >
              Checkout & Apply
            </button>
          )}
        </Col>
      </Row>
    </div>
  );
};

FullQuoteInfo.defaultProps = {
  showCheckout: true,
};

export default FullQuoteInfo;
