import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import _ from "lodash";

import { formatCurrency } from "utils/currency";
import { normaliseNumber } from "utils/general";
import { clearPxRequests, updateQuote } from "services";

import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";

const QuoteDetails = (props) => {
  const [showMileageError, setShowMileageError] = useState(false);
  const [showTermError, setShowTermError] = useState(false);

  const handleFieldChange = (e) => {
    let name = e.target.name;
    let value = parseInt(normaliseNumber(e.target.value));

    props.setShowRecalcMsg(false);

    if (name === "term") {
      props.setEditQuote({
        ...props.editQuote,
        term: value,
      });

      setShowTermError(value > props.offer.term);
    } else if (name === "mileage") {
      props.setEditQuote({
        ...props.editQuote,
        annualMileage: value ? value.toLocaleString("en-GB") : 0,
      });
    } else if (name === "deposit") {
      props.setEditQuote({
        ...props.editQuote,
        deposit: value ? formatCurrency(value, 0) : 0,
      });
    }
  };

  const showRecalcMsg = _.debounce(() => {
    props.setShowRecalcMsg(true);
  }, 500);

  const handleUnderstand = () => {
    showRecalcMsg();
    setShowMileageError(false);
    setShowTermError(false);
  }

  const checkQuoteError = (e) => {
    let name = e.target.name;
    let value = parseInt(normaliseNumber(e.target.value));

    if (name === "term") {
      let showError = value > props.offer.term;

      setShowTermError(showError);;

      if (!showError) {
        showRecalcMsg();
      }
    } else if (name === "mileage") {
      let showError = value < props.offer.annualMileage;

      setShowMileageError(value < props.offer.annualMileage);

      if (!showError) {
        showRecalcMsg();
      }
    } else {
      showRecalcMsg();
    }
  };

  const handleShowPx = () => {
    props.setAddPartExchange(true);
    props.setShowVrmInput(true);
  };

  const handleRemovePx = () => {
    props.setEditQuote({
      ...props.editQuote,
      includeEquity: false,
    });

    clearPxRequests(props.editQuote?.applicationGuid);
  };

  return (
    <>
      { showMileageError === false && showTermError === false && (
        <>
          <div className="flex justify-between items-center mb-2 quote-builder-header">
            <h4>Build your offer</h4>

            { props.quoteChanged && (
              <button
                className="undo-button"
                onClick={props.onQuoteReset}
              >
                Undo changes
              </button>
            )}
          </div>

          <p className="info-text">
            Adjust the information below to view your personalised offers and
            eligible lenders.
          </p>
        </>
      )}

      <div className="quote-info">
        {showMileageError ? (
          <>
            <div className="mt-auto">
              <p className="info-text fw-bold mt-3">
                Please ensure you select the mileage you require. If you
                exceed your proposed mileage you may be liable for an excess
                mileage charge at the end of the finance agreement. This
                will affect PCP products only.
              </p>

              <p className="underline text-sm text-white font-bold cursor-pointer">
                More information
              </p>
            </div>

            <button
              className="green mt-auto"
              onClick={handleUnderstand}
            >
              I understand
            </button>
          </>
        ) : showTermError ? (
          <>
            <div className="mt-auto">
              <p className="info-text fw-bold mt-3">
                Increasing the term of your finance agreement will reduce
                your monthly payments and may result in you paying more
                interest over the increased term.
              </p>

              <p className="underline text-sm text-white font-bold cursor-pointer">
                More information
              </p>
            </div>

            <button
              className="green mt-auto"
              onClick={handleUnderstand}
            >
              I understand
            </button>
          </>
        ) : (
          <>
            <div className="quote-info-row">
              <div className="quote-info-label">Loan term:</div>
              <div className="quote-info-field">
                <Form.Select
                  name="term"
                  size="sm"
                  value={props.editQuote?.term}
                  onChange={(e) => {
                    handleFieldChange(e);
                    checkQuoteError(e)
                  }}
                  disabled={showMileageError || showTermError}
                >
                  {props.termOptions.map((option) => (
                    <option value={option} key={option}>
                      {option} months
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="quote-info-row">
              <div className="quote-info-label">Annual mileage:</div>
              <div className="quote-info-field">
                <Form.Control
                  name="mileage"
                  value={props.editQuote?.annualMileage?.toLocaleString("en-GB")}
                  onChange={handleFieldChange}
                  onBlur={checkQuoteError}
                  size="sm"
                  disabled={showMileageError || showTermError}
                />
              </div>
            </div>

            <h4 className="subtitle">Deposit</h4>

            <div className="quote-info-row">
              <div className="quote-info-label">Cash deposit value:</div>
              <div className="quote-info-field">
                <Form.Control
                  name="deposit"
                  value={formatCurrency(props.editQuote.deposit, 0)}
                  onChange={handleFieldChange}
                  onBlur={checkQuoteError}
                  size="sm"
                />
              </div>
            </div>
            {props.editQuote?.includeEquity ? (
              <div className="quote-info-row">
                <div className="quote-info-label">Part exchange added</div>
                <div className="quote-info-field part-exchange-container">
                  <div onClick={handleRemovePx}>X</div>
                  <Form.Control
                    name="equity"
                    value={formatCurrency(props.editQuote.equity, 0)}
                    size="sm"
                    readOnly
                  />
                </div>
              </div>
            ) : (
              <div className="quote-info-row">
                <div className="quote-info-label flex gap-2 items-center">
                  Add part exchange?

                  <OverlayTrigger
                    placement="top-start"
                    overlay={
                      <Tooltip>
                        Content required here
                      </Tooltip>
                    }
                    delay={{ show: 250, hide: 400 }}
                  >
                    <InfoIcon className="fill-white" />
                  </OverlayTrigger>
                </div>
                <div className="quote-info-field">
                  <button onClick={handleShowPx}>Yes</button>
                </div>
              </div>
            )}
            <div className="quote-info-row mb-2">
              <div className="quote-info-label">Total deposit amount:</div>
              <div className="quote-info-field total-deposit">
                {formatCurrency(
                  normaliseNumber(props.editQuote?.deposit) +
                    (props.editQuote.includeEquity ? props.editQuote?.equity : 0),
                  0
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {!showMileageError && !showTermError && (
        <>
          <div className="quote-actions">
            <button
              className="green"
              onClick={props.handleUpdateQuote}
              disabled={props.quoteLoading}
            >
              {props.quoteLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                <>Recalculate monthly payment</>
              )}
            </button>
          </div>
        </>
      )}
    </>
  );
};

QuoteDetails.defaultProps = {
  mobile: false,
}

export default QuoteDetails;
