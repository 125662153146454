import React, { useState } from "react";
import { Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";

import { formatCurrency } from "utils/currency";
import { normaliseNumber } from "utils/general";
import { addPx, getPxValuation, vrmSearch } from "services";
import ButtonOptions from "components/input/ButtonOptions";

import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";

const AddPartExchange = (props) => {
  const [hasOutstandingFinance, setHasOutstandingFinance] = useState(null);
  const [mileage, setMileage] = useState(null);
  const [outstanding, setOutstanding] = useState(null);
  const [pxEquity, setPxEquity] = useState(null);
  const [pxValuation, setPxValuation] = useState(null);
  const [pxVehicle, setPxVehicle] = useState(null);
  const [serviceHistory, setServiceHistory] = useState(null);
  const [showContinue, setShowContinue] = useState(false);
  const [showMileage, setShowMileage] = useState(false);
  const [showMileageError, setShowMileageError] = useState(false);
  const [showMileageInputError, setShowMileageInputError] = useState(false);
  const [showPxError, setShowPxError] = useState(false);
  const [showPxValue, setShowPxValue] = useState(false);
  const [showServiceHistoryError, setShowServiceHistoryError] = useState(false);
  const [showTermError, setShowTermError] = useState(false);
  const [showVehicleError, setShowVehicleError] = useState(false);
  const [vrm, setVrm] = useState(null);

  const handleVrmSearch = () => {
    if (vrm) {
      props.setQuoteLoading(true);
      vrmSearch({ pxVehicleVRM: vrm })
        .then((res) => {
          setPxVehicle(res);
          setShowVehicleError(false);
          setShowPxError(false);
          setShowContinue(false);
        })
        .catch((err) => {
          setShowVehicleError(true);
          setPxVehicle({});
        })
        .finally(() => {
          props.setQuoteLoading(false);
        });
    }
  };

  const resetVrmSearch = () => {
    setPxVehicle(null);
    setShowVehicleError(false);
    setShowPxError(false);
  };

  const handleCanContinue = () => {
    setShowContinue(true);
    setShowPxError(false);
    handleShowMileage();
  };

  const handleShowMileage = () => {
    props.setShowVrmInput(false);
    setShowMileage(true);
  };

  const handleShowOutstanding = () => {
    if (mileage && serviceHistory) {
      setShowMileage(false);
    } else {
      if (!mileage) {
        setShowMileageInputError(true);
      }

      if (!serviceHistory) {
        setShowServiceHistoryError(true);
      }
    }
  };

  const handleSubmitPx = () => {
    props.setQuoteLoading(true);

    getPxValuation({
      vrm,
      mileage,
    }).then(res => {
      setShowPxValue(true);
      setPxEquity(res.valuation - (outstanding ?? 0));
      setPxValuation(res.valuation);
    }).finally(() => {
      props.setQuoteLoading(false);
    });
  }

  const handleEndPx = () => {
    props.setQuoteLoading(true);
    addPx(props.offer.applicationGuid, {
      vrm,
      mileage,
      outstanding: outstanding ?? 0,
      serviceHistory,
      email: props.editQuote?.dealer?.email,
    })
      .then((res) => {
        props.setEditQuote({
          ...props.editQuote,
          includeEquity: true,
          equity: res.equity,
        });

        props.setAddPartExchange(false);
        setHasOutstandingFinance(null);
        setPxVehicle(null);
        setShowContinue(false);
        setShowMileage(false);
        setShowPxError(false);
        setShowVehicleError(false);
        setShowPxValue(false);
        props.setShowVrmInput(false);
        setVrm(null);

	props.onPxUpdate(res);
      })        
      .finally(() => {
        props.setQuoteLoading(false);
      }); 
  };

  const handleCancelPx = () => {
    props.setEditQuote({
      ...props.editQuote,
      includeEquity: false,
    });

    props.setAddPartExchange(false);
    setHasOutstandingFinance(null);
    setPxVehicle(null); 
    setShowContinue(false);
    setShowMileage(false);
    setShowPxError(false);
    setShowVehicleError(false);
    setShowPxValue(false);
    props.setShowVrmInput(false);
    setVrm(null);
  }

  return (
    <>
      <button
        onClick={(e) => props.setAddPartExchange(false)}
        className="cancel-px"
      >
        Cancel
      </button>

      <h4 className="flex items-center gap-2">
        Add your part exchange

        <OverlayTrigger
          placement="top-start"
          overlay={
            <Tooltip>
              Sample text
            </Tooltip>
          }
          delay={{ show: 250, hide: 400 }}
        >
          <InfoIcon className="fill-white" />
        </OverlayTrigger>
      </h4>

      <p className="info-text mb-2">
        <strong>Please note:</strong>
        The part exchange value is an estimate and your car will need to be
        viewed by the dealer before the part exchange value is confirmed.
      </p>

      <div className="quote-info justify-content-start px-form">
        {props.showVrmInput ? (
          <>
            <div className="quote-info-row mb-2">
              <div className="quote-info-label w-100">
                What is the vehicle registration number?
              </div>
            </div>
            <div className="quote-info-row mb-2">
              <div className="quote-info-field full vrm-input">
                <Form.Control
                  name="vrm"
                  value={vrm}
                  onChange={(e) => setVrm(e.target.value)}
                />
                <button onClick={handleVrmSearch} className="vrm-button">
                  Enter
                </button>
              </div>
            </div>

            {pxVehicle && (
              <>
                {showVehicleError === false ? (
                  <>
                    <div className="quote-info-row">
                      <div className="quote-info-label">
                        Is this your vehicle?
                      </div>
                      <div
                        className="quote-info-field d-flex"
                        style={{ gap: 10 }}
                      >
                        <button
                          onClick={handleCanContinue}
                          className="yes-button"
                        >
                          Yes
                        </button>
                        <button
                          onClick={(e) => setShowPxError(true)}
                          className="no-button"
                        >
                          No
                        </button>
                      </div>
                    </div>

                    {showPxError === false ? (
                      <>
                        <div className="quote-px-info">
                          <p>
                            {pxVehicle.modelYear} {pxVehicle.make}
                          </p>
                          <p>
                            {pxVehicle.fuelType} ({pxVehicle.colour})
                          </p>
                        </div>

                        {showContinue && (
                          <div className="quote-actions">
                            <button
                              onClick={handleShowMileage}
                              className="green"
                            >
                              Continue
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="px-error">
                          Please ensure you’ve entered the correct
                          registration plate and search again
                        </div>
                        <div className="quote-actions">
                          <button
                            className="green"
                            onClick={resetVrmSearch}
                          >
                            Go back
                          </button>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="px-error">
                      Please ensure you’ve entered the correct registration
                      plate and search again
                    </div>
                    <div className="quote-actions">
                      <button className="green" onClick={resetVrmSearch}>
                        Go back
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className="quote-px-info">
              <div className="vehicle-vrm">{pxVehicle.pxVehicleVRM}</div>
              <p>
                {pxVehicle.modelYear} {pxVehicle.make} {pxVehicle.fuelType}{" "}
                ({pxVehicle.colour})
              </p>
            </div>

            {showMileage ? (
              <>
                <div className="quote-info-row">
                  <div className="quote-info-label">
                    What is the current mileage?
                  </div>
                </div>
                <div className="quote-info-row">
                  <div className="quote-info-field full">
                    <Form.Control
                      name="mileage"
                      value={
                        outstanding
                          ? outstanding.toLocaleString("en-GB")
                          : outstanding
                      }
                      onChange={(e) => setMileage(e.target.value)}
                      className="text-center"
                    />

                    {showMileageInputError && (
                      <p className="form-error">
                        Please specify current mileage
                      </p>
                    )}
                  </div>
                </div>
                <div className="quote-info-row">
                  <div className="quote-info-label">
                    How much service history does the vehicle have?
                  </div>
                </div>
                <div className="quote-info-row form-container">
                  <div className="quote-info-field full form-item">
                    <ButtonOptions
                      name="serviceHistory"
                      options={[
                        {
                          value: "Full",
                          label: "Full",
                        },
                        {
                          value: "Partial",
                          label: "Part",
                        },
                        {
                          value: "None",
                          label: "None",
                        },
                      ]}
                      selected={serviceHistory}
                      onClick={(name, option) =>
                        setServiceHistory(option.value)
                      }
                      className="service-history-options"
                    />

                    <span className="form-item-info text-white">
                      <OverlayTrigger
                        placement="top"
                        delay={{ hide: 400, show: 300 }}
                        overlay={
                          <Tooltip>
                            <p className="mb-3">
                              <strong>Full service history</strong> indicates that the vehicle has been regularly maintained 
                              and serviced according to the manufacturer’s recommended schedule.
                            </p>
                            <p className="mb-3">
                              <strong>Partial service history</strong> means that some but not all of the vehicle’s service 
                              and maintenance records are available.
                            </p>
                            <p>
                              <strong>No service history</strong> means there are no records available of 
                              the vehicle’s maintenance and service.
                            </p>
                          </Tooltip>
                        }
                      >
                        <div className="flex gap-x-1">
                          Service history explained
                          <InfoIcon className="service-history-info" />
                        </div>
                      </OverlayTrigger>
                    </span>

                    {showServiceHistoryError && (
                      <p className="form-error">
                        Please specify service history
                      </p>
                    )}
                  </div>
                </div>
                <div className="quote-actions">
                  <button className="green" onClick={handleShowOutstanding}>
                    Continue
                  </button>
                </div>
              </>
            ) : showPxValue === false ? (
              <div className="form-container">
                <div className="quote-info-row form-item flex-col" style={{ alignItems: "flex-start" }}>
                  <div className="quote-info-label w-100">
                    Do you have any outstanding finance on the vehicle?
                  </div>
                  <span className="form-item-info text-white">
                    <OverlayTrigger
                      placement="top"
                      delay={{ hide: 400, show: 300 }}
                      overlay={
                        <Tooltip>
                          <p className="mb-3">
                            Sample text
                          </p>
                        </Tooltip>
                      }
                    >
                      <div className="flex gap-x-1">
                        Why do we need this information?
                      </div>
                    </OverlayTrigger>
                  </span>
                </div>
                <div className="quote-info-row">
                  <div
                    className="quote-info-field full d-flex"
                    style={{ gap: 10 }}
                  >
                    <button
                      onClick={(e) => setHasOutstandingFinance(true)}
                      className={
                        hasOutstandingFinance === true ? "yes-button" : ""
                      }
                    >
                      Yes
                    </button>
                    <button
                      className={
                        hasOutstandingFinance === false ? "yes-button" : ""
                      }
                      onClick={(e) => setHasOutstandingFinance(false)}
                    >
                      No
                    </button>
                  </div>
                </div>

                {hasOutstandingFinance === true && (
                  <>
                    <div className="quote-info-row">
                      <div className="quote-info-label">
                        What is the outstanding amount?
                      </div>
                    </div>
                    <div className="quote-info-row mt-1">
                      <div className="quote-info-field full">
                        <Form.Control
                          name="outstanding"
                          placeholder="Enter outstanding amount"
                          className="text-center"
                          value={
                            outstanding
                              ? outstanding.toLocaleString("en-GB")
                              : outstanding
                          }
                          onChange={(e) => setOutstanding(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="quote-actions mt-2">
                  <button
                    className="green"
                    onClick={handleSubmitPx}
                    disabled={props.quoteLoading}
                  >
                    {props.quoteLoading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                      />
                    ) : (
                      <>Check available equity</>
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <>
                { pxEquity <= 0 ? (
                  <>
                    <div className="px-error">
                      Unfortunately it appears that you do not have any equity in the provided vehicle to use towards your deposit for your new car. 
                      The dealer may still buy your part exchange but we have removed it for the purpose of your finance quote.
                    </div>

                    <div className="quote-actions mt-2">
                      <button
                        className="green"
                        onClick={handleCancelPx}
                      > 
                        Confirm
                      </button>
                    </div>
                  </>
                ) : pxEquity >= props.editQuote?.vehicle?.salesValue ? (
                  <>
                    <div className="px-error">
                      The equity in your part exchange is more than the value of the vehicle you wish to purchase, which indicates you may not need finance. 
                      The dealer may still buy your part exchange but we have removed it for the purpose of your finance quote.
                    </div>

                    <div className="quote-actions mt-2">
                      <button
                        className="green"
                        onClick={handleCancelPx}
                      >
                        Confirm
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="px-error w-100">
                      Based on the information provided we estimate that your vehicle is worth { formatCurrency(pxValuation, 0) } and 
                      you have { formatCurrency(pxEquity, 0) } equity in this car. Would you like to use this equity as a deposit in your quote? 
                    </div>

                    <div className="quote-actions mt-2 quote-info-row">
                      <div className="quote-info-field w-100 flex items-center gap-5 full">
                        <button
                          className="yes-button w-50"
                          onClick={handleEndPx}
                          disabled={props.quoteLoading}
                        >
                          {props.quoteLoading ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          ) : (
                            <>Yes</>
                          )}
                        </button>

                        <button
                          className="no-button w-50"
                          onClick={handleCancelPx}
                          disabled={props.quoteLoading}
                        >   
                          {props.quoteLoading ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          ) : (
                            <>No</>
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

AddPartExchange.defaultProps = {
  mobile: false,
}

export default AddPartExchange;
