import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { matchRoutes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as ChecklistIcon } from "assets/images/features/checklist.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as RealtimeIcon } from "assets/images/features/realtime.svg";
import { ReactComponent as SimpleIcon } from "assets/images/features/simple.svg";
import { ReactComponent as TickIcon } from "assets/images/icons/tick.svg";

import { guestRoutes } from "routes";
import BenefitsList from "components/BenefitsList";
import FeaturesList from "components/FeaturesList";

const GuestLayout = (props) => {
  const user = useSelector((state) => state.auth.user);

  const location = useLocation();
  const result = matchRoutes(guestRoutes, location);
  const route = (result && result.length > 0 ? result[0]?.route : null);

  return (
    <div className={`guest-layout page-${route ? route?.name.toLowerCase() : "home"}`}>
      <div className="page-logo">
        <Logo />
      </div>

      <div className="content-container">
        <div className="content">
          <Container fluid>
            <Row>
              <Col lg={6} className="left-container">
                <div className="left-content">
                  <div className="logo-container">
                    <Logo className="content-logo" />
                  </div>

                  {user ? <BenefitsList /> : <FeaturesList showLogo={false} />}
                </div>
              </Col>
              <Col xs={12} lg={6} className="right-container">
                {props.children}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <footer>
        <div className="company-name">&copy; Fastcheck</div>
        <div className="links">
          <a href="#" className="about-link">
            About Fastcheck
          </a>
          <a href="#">Contact</a>
          <a href="#">Privacy & Terms</a>
        </div>
      </footer>
    </div>
  );
};

export default GuestLayout;
