import React, { useEffect, useState } from "react";
import { parse, parseISO, format } from "date-fns";

import { disablePasskey, getProfile, updateProfile } from "services";
import { useDataLoading } from "components/loader/LoaderHook";
import Webpass, { webpassBase } from "services/webpass";
import DrivingInfo from "components/forms/profile/DrivingInfo";
import Loader from "components/Loader";
import MyProfileForm from "components/forms/MyProfileForm";
import Occupation from "components/forms/profile/Occupation";
import PageTitle from "components/misc/PageTitle";
import PersonalInfo from "components/forms/profile/PersonalInfo";

import { ReactComponent as KeyIcon } from "assets/images/icons/key_icon.svg";
import { ReactComponent as FingerprintIcon } from "assets/images/icons/fingerprint.svg";
import { ReactComponent as FaceIdIcon } from "assets/images/icons/faceid.svg";

const MyProfile = () => {
  const { loading, setLoading } = useDataLoading(true);

  const [data, setData] = useState({});
  const [section, setSection] = useState("profile");
  const [supportsPasskeys, setSupportsPasskeys] = useState(false);

  useEffect(() => {
    getProfileData();

    setSupportsPasskeys(Webpass.isSupported());
  }, []);

  const getProfileData = () => {
    getProfile().then((res) => {
      commitData(res.data);
      setLoading(false);
    });
  };

  const commitData = (info) => {
    let dob = parse(info?.dob, "yyyy-MM-dd", new Date());

    setData({
      ...info,
      dob_day: format(dob, "dd"),
      dob_month: format(dob, "MM"),
      dob_year: format(dob, "yyyy"),
    });
  };

  const updateUserData = (newData) => {
    const dataObj = {
      ...data,
      ...newData,
    };

    updateProfile(dataObj)
      .then((res) => {
        commitData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });

    setData(dataObj);
  };

  const handleCreatePasskey = async () => {
    const { credential, success, error } = await webpassBase().attest(
      "/journey/webauthn/register/options",
      "/journey/webauthn/register"
    );

    if (success) {
      getProfileData();
    } else {
      alert("Unable to add passkey");
    }
  };

  const handleRemovePasskey = (passkey) => {
    disablePasskey(passkey.id).then((res) => {
      let activePasskeys = [...data.webAuthnCredentials];
      activePasskeys = activePasskeys.map((key) => {
        if (key.id === passkey.id) {
          key.disabled_at = key.disabled_at
            ? null
            : format(new Date(), "yyyy-MM-dd HH:mm:ss");
        }

        return key;
      });

      setData({
        ...data,
        webAuthnCredentials: activePasskeys,
      });
    });
  };

  const commonProps = {
    data,
    onUpdate: (data) => updateUserData(data),
    onProceed: () => setSection("profile"),
    profile: true,
  };

  const renderSection = () => {
    switch (section) {
      case "profile":
        return (
          <MyProfileForm
            data={data}
            setStep={(section) => setSection(section)}
          />
        );
      case "driving":
        return <DrivingInfo {...commonProps} />;
      case "occupation":
        return <Occupation {...commonProps} />;
      case "personal":
        return <PersonalInfo {...commonProps} />;
    }
  };

  return (
    <>
      {loading ? (
        <div className="dash-content flex items-center justify-center h-100">
          <Loader />
        </div>
      ) : (
        <>
          <PageTitle
            title="My profile"
            subText="Need some cool text to go here giving an oversight to the user for what they’re seeing below and how they can interact with it."
          />
          <div className="dash-content profile-container">
            <div className="profile-details">{renderSection()}</div>

            <div className="profile-passkeys">
              <div>
                <h4>Your Passkey(s)</h4>
                <p>
                  Want an easy, fast and more secure way to obtain and access you’re pre-approved quotes? 
                  Set up a Passkey which allows you to access our service using your face or fingerprint!
                </p>
              </div>

              <div className="mt-5 passkeys-list">
                {data?.webAuthnCredentials && (
                  <>
                    {data?.webAuthnCredentials.map((passkey) => (
                      <div
                        className={`${
                          passkey.disabled_at ? "disabled" : "enabled"
                        } d-flex align-items-center mb-3`}
                        style={{ gap: 40 }}
                      >
                        <div className="flex gap-3">
                          <FaceIdIcon width={50} height={50} />
                          <FingerprintIcon width={50} height={50} />
                        </div>
                        <div>
                          <p>ID: {passkey.id}</p>
                          <p>
                            Date created:{" "}
                            {format(parseISO(passkey.created_at), "dd/MM/yyyy")}
                          </p>
                        </div>
                        <div className="ml-auto">
                          <button
                            className="grey"
                            onClick={(e) => handleRemovePasskey(passkey)}
                          >
                            {passkey.disabled_at ? (
                              <>Enable passkey</>
                            ) : (
                              <>Disable passkey</>
                            )}
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>

              {supportsPasskeys && (
                <button className="create-button" onClick={handleCreatePasskey}>
                  Create a new passkey
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MyProfile;
