import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { matchRoutes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ReactComponent as ChecklistIcon } from "assets/images/features/checklist.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as RealtimeIcon } from "assets/images/features/realtime.svg";
import { ReactComponent as SimpleIcon } from "assets/images/features/simple.svg";

import { logout } from "store/actions/auth";
import { authRoutes } from "routes";
import SidebarMenu from "components/menus/sidebarMenu";
import MobileMenu from "components/menus/mobileMenu";

const AuthLayout = (props) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const result = matchRoutes(authRoutes, location);
  const route = (result && result.length > 0 ? result[0]?.route : null);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <MobileMenu />
      <div className={`auth-layout page-${route ? route?.name.replace(/ /g, '-').toLowerCase() : "dashboard"}`}>
        <div className="sidebar-menu-container">
          <SidebarMenu />
        </div>
        <div className="auth-content">{props.children}</div>
        <footer>
          <div className="company-name">&copy; Fastcheck</div>
          <div className="links">
            <a href="#">Contact</a>
            <a href="#">Privacy & Terms</a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default AuthLayout;
