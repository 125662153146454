import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { formatCurrency } from "utils/currency";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";

const Offer = (props) => {
  const navigate = useNavigate();

  const handleArchiveClick = () => {
    props.onArchive(props.offer.applicationGuid);
  };

  return (
    <div className="offers-card basic-card">
      <div className="offers-vehicle">
        <div className="vehicle-image">
          <img src={props.offer?.vehicle?.vehicleImage} alt="Vehicle" />
        </div>
        <div className="offers-vehicle-details">
          <div className="make-model-description">
            <div className="make-model">
              {props?.offer?.vehicle?.make} {props?.offer?.vehicle?.model}
            </div>
            <div className="description">
              {props.offer?.vehicle?.modelYear} {props.offer?.vehicle?.fuel}{" "}
              {props.offer?.vehicle.transmission}
            </div>
          </div>
          <div className="price">
            Cash Price:{" "}
            <h4>{formatCurrency(props.offer?.vehicle?.salesValue)}</h4>
          </div>
          {props.showStatus && (
            <div className="flex">
              <div className={`quote-status status-${props.offer?.quoteStatus.toLowerCase()}`}>
                {props.offer?.quoteStatus}
              </div>
            </div>
          )}
        </div>
        <div className="offers-lender-small">
          <div className="offers-dealer-logo">
            <img src={props?.offer?.dealer?.logo_url} alt="Lender Logo" />
          </div>
        </div>
      </div>
      <div className="offers-lender">
        <div className="offers-dealer-logo">
          <img src={props?.offer?.dealer?.logo_url} alt="Lender Logo" />
        </div>
      </div>
      <div className="offers-smaller-screen">
        <div className="offers-buttons">
          {props.showArchiveButton ? (
            <div className="offers-buttons">
              <button className="archive-button" onClick={handleArchiveClick}>
                Archive quote
              </button>
            </div>
          ) : (
            <>
              <button
                onClick={(e) => navigate(`/offers/${props.offer?.applicationGuid}`)}
               className="full-quote-button"
              >
                View full quote
              </button>
              <button
                onClick={(e) => navigate(`/offers/${props.offer?.applicationGuid}`)}
                className="checkout-button"
              >
                Checkout & Apply
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Offer;
