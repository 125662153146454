import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

import { getMetaData, googleRegister, sendVerificationCode } from "services";
import { setMetaData } from "store/actions/general";
import { setLoginData } from "store/actions/auth";
import googleIcon from "assets/images/icons/google.png";

import { ReactComponent as AppleIcon } from "assets/images/icons/apple.svg";

const RegisterPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const phoneRef = useRef();

  const [showError, setShowError] = useState(false);
  const [showGoogleError, setShowGoogleError] = useState(false);

  useEffect(() => {
    getMetaData().then((res) => {
      dispatch(setMetaData(res));
    });
  }, []);

  const handleGoogleRegisterSuccess = (data) => {
    googleRegister(data.access_token)
      .then((res) => {
        props.onUpdate(res);
        props.onProceed();
      })
      .catch((err) => {
        setShowGoogleError(err.response.data.message ?? err.message);
      });
  };

  const handleGoogleRegister = useGoogleLogin({
    onSuccess: (response) => handleGoogleRegisterSuccess(response),
    onError: (error) => console.log("error", error),
  });

  const onProceed = () => {
    if (
      phoneRef?.current?.value &&
      phoneRef?.current?.value?.match(/^(\+447|07)[0-9]{9}$/)
    ) {
      props.onUpdate({
        phone: phoneRef?.current?.value,
      });

      sendVerificationCode({ phone: phoneRef?.current?.value, type: "register" })
        .then((res) => {
          props.onProceed();
        })
        .catch((err) => {
          if (err.response.data.userFound) {
            dispatch(setLoginData(err.response.data));
            navigate('/');
          } else {
            setShowError(err.response.data.error ?? err.message);
          }
        });
    } else {
      setShowError(
        "Please enter a valid UK mobile number, starting with either +447 or 07"
      );
    }
  };

  return (
    <div className="guest-page-register">
      <div className="guest-content-register">
        <div className="guest-title-register">Register for an account</div>

        <div className="form-container-register">
          <div className="form-item">
            <label className="thin">Telephone</label>

            <input
              type="phone"
              name="phone"
              placeholder="Enter your telephone"
              ref={phoneRef}
            />

            {showError && <p className="form-error">{showError}</p>}
          </div>

          <div className="form-item">
            <button className="light-blue full" onClick={onProceed}>
              Send confirmation code
            </button>
          </div>

          <div className="or-container">Or</div>

          <div className="buttons-list">
            <button
              className="login-button full"
              onClick={handleGoogleRegister}
            >
              <img src={googleIcon} />
              <p>Sign up with Google</p>
            </button>

            {showGoogleError && <p className="form-error">{showGoogleError}</p>}

            {/*
            <button className="outline full">
              <AppleIcon />
              <p>Sign up with Apple</p>
            </button>
            */}
          </div>
        </div>
      </div>

      <div className="bottom-content-register">
        Already registered with Fastcheck?
        <a onClick={(e) => navigate("/")}>Login here</a>
      </div>
    </div>
  );
};

export default RegisterPage;
