import React from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as HelpIcon } from "assets/images/dashboard_icons/help.svg";
import { ReactComponent as HomeIcon } from "assets/images/dashboard_icons/home.svg";
import { ReactComponent as LogoutIcon } from "assets/images/dashboard_icons/logout.svg";
import { ReactComponent as MyOffersIcon } from "assets/images/dashboard_icons/myoffers.svg";
import { ReactComponent as MyProfileIcon } from "assets/images/dashboard_icons/myprofile.svg";
import { ReactComponent as NotificationsIcon } from "assets/images/dashboard_icons/notifications.svg";
import { ReactComponent as RetailersIcon } from "assets/images/dashboard_icons/retailers.svg";
import { ReactComponent as SearchIcon } from "assets/images/dashboard_icons/search.svg";
import { ReactComponent as SubmittedAppsIcon } from "assets/images/dashboard_icons/submittedapps.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { logout } from "store/actions/auth";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";

const menuItems = [
  { icon: HomeIcon, label: "Home", url: "/" },
  { icon: MyOffersIcon, label: "My offers", url: "/offers" },
  {
    icon: SubmittedAppsIcon,
    label: "Submitted applications",
    url: "/applications/submitted",
  },
  { icon: RetailersIcon, label: "Retailers", url: "/retailers" },
  { icon: MyProfileIcon, label: "My profile", url: "/myprofile" },
  { icon: NotificationsIcon, label: "Notifications", url: "/notifications" },
  { icon: HelpIcon, label: "Help center", url: "/helpcenter" },
];

const MobileMenu = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div
        className={`mobile-header mobile-hide ${
          isOpen ? "mobile-header-white" : ""
        }`}
      >
        <div className="logo">
          <Logo />
        </div>
        <div className="hamburger-icon">
          <Hamburger
            toggled={isOpen}
            toggle={setIsOpen}
            color={"#0a1044"}
            rounded={true}
            label={"Show Menu"}
            size={20}
          />
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <>
            <div
              className="mobile-menu-overlay"
              onClick={() => setIsOpen(false)}
            />
            <div className="mobile-menu-container mobile-hide">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="mobile-menu"
              >
                <div className="mobile-menu-items">
                  <div className="search-bar">
                    <SearchIcon className="search-icon" />
                    <input type="text" placeholder="Search" />
                  </div>

                  {menuItems.map((menuItem, index) => (
                    <motion.div
                      key={index}
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        delay: 0.1 + index / 10,
                      }}
                    >
                      <Link
                        onClick={() => setIsOpen((prev) => !prev)}
                        to={menuItem.url}
                        className="menu-item"
                      >
                        <menuItem.icon className="menu-item-icon" />
                        {menuItem.label}
                      </Link>
                    </motion.div>
                  ))}
                  <button
                    className="mobile-logout-button"
                    onClick={handleLogout}
                  >
                    <LogoutIcon className="logout-icon" />
                    Logout
                  </button>
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu;
